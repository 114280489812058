<template>
    <h-row justify="center">
        <h-col class="py-0">

            <h-row class="pt-6 pb-3" justify="center" align="center">
                <h-col cols="auto">
                    <h1>{{ utente.NOME }} {{ utente.COGNOME }}</h1>
                </h-col>
            </h-row>

            <base-scheda-generale :model="utente" :items="items">

                <template #password>

                    <button-tooltip class="ml-2 px-6"
                                    text="CAMBIA"
                                    color="primary"
                                    :disabled="$store.getters.isSystem || $store.getters.isGuest"
                                    :loading="!idLinguaFk || isGlobalLoading"
                                    tooltip="Click per cambiare la password"
                                    @click.stop="isCambiaPassword = true"
                    ></button-tooltip>

                    <change-password-dialog v-if="isCambiaPassword"
                                            :dialog="isCambiaPassword"
                                            can-close
                                            @close="isCambiaPassword = false"
                    ></change-password-dialog>

                </template>

                <template #lingua>

                    <base-form-field-api-select models-name="lingue"
                                                v-model="idLinguaFk"
                                                item-text="NOME"
                                                item-value="ID_REC"
                                                label="Lingua"
                                                placeholder="Seleziona una lingua"
                                                :clearable="false"
                                                :loading="!idLinguaFk"
                                                prepend-icon="fa-flag"
                                                @finish="onFinish"
                                                @input="onChangeLingua"
                    ></base-form-field-api-select>

                </template>

            </base-scheda-generale>

        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseSchedaGenerale from '@/components/aaaProject/scheda/BaseSchedaGenerale';
    import ButtonTooltip from '@/components/aaaGenerics/commons/ButtonTooltip';
    import ChangePasswordDialog from '@/components/aaaProject/commons/ChangePasswordDialog';
    import BaseFormFieldApiSelect from '@/components/aaaProject/form/BaseFormFieldApiSelect';
    import moment from '@/moment';

    export default {
        name: 'Profile',
        components: {HRow, HCol, BaseFormFieldApiSelect, ChangePasswordDialog, ButtonTooltip, BaseSchedaGenerale},
        data: () => ({
            apiTimer: false,
            idLinguaFk: null,
            isCambiaPassword: false
        }),
        computed: {
            isGlobalLoading: function () {
                return this.$store.state.servicesModule.isGlobalLoading;
            },
            utente: function () {
                return this.$store.state.authModule.utente;
            },
            items: function () {
                return [
                    {label: 'Profilo', data: this.utente.PROFILO},
                    {label: 'Grado', data: this.utente.GRADO},
                    {label: 'Nome', data: this.utente.NOME},
                    {label: 'Cognome', data: this.utente.COGNOME},
                    {label: 'Email', data: this.utente.EMAIL},
                    {label: 'Iscrizione', data: moment.toDate(this.utente.CREATOR_TIMESTAMP)},
                    {label: 'Ultimo Accesso', data: this.utente.DATA_LOGIN ? moment.toDate(this.utente.DATA_LOGIN) : 'MAI'},
                    {
                        label: 'Disattivazione Manuale',
                        data: this.utente.DATA_DISATTIVAZIONE ? moment.toDate(this.utente.DATA_DISATTIVAZIONE) : 'MAI'
                    },
                    {
                        label: 'Ultimo Cambio Password',
                        data: this.utente.DATA_CAMBIO_PSW ? moment.toDate(this.utente.DATA_CAMBIO_PSW) : 'MAI'
                    },
                    {
                        label: 'Cliente',
                        data: !this.utente.CL_NOME ? 'Non associato a un cliente' : this.utente.CL_NOME
                    },
                    {label: 'Password'},
                    {label: 'Lingua', noLabel: true}
                ]
            }
        },
        methods: {
            onFinish(response) {
                if (response.totalCount) {
                    this.idLinguaFk = this.utente.ID_LINGUA_FK;
                }
            },
            onChangeLingua() {
                function doRequest(_this) {
                    if (!_this.isGlobalLoading) {
                        window.clearInterval(_this.apiTimer);

                        //PUT nuova password
                        _this.$store.dispatch('defaultRequest', {
                            api: 'PUT_UTENZA_LINGUA',
                            paylod: _this.idLinguaFk,
                            doResponse: () => {
                                _this.utente.ID_LINGUA_FK = _this.idLinguaFk;
                                _this.$store.dispatch('activeSnackbar', {
                                    color: 'success',
                                    text: 'Lingua preferita salvata con successo'
                                });
                            },
                            doClose: () => {
                            }
                        });
                    }
                }

                if (this.idLinguaFk !== this.utente.ID_LINGUA_FK) {
                    this.apiTimer = window.setInterval(() => doRequest(this), 1000);
                    doRequest(this);
                }

            }
        },
        beforeDestroy() {
            window.clearInterval(this.apiTimer);
        }
    }
</script>

<style scoped>

</style>
